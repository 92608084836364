document.addEventListener("DOMContentLoaded", () => {
  const trackTrial = function () {
    // Get GA client ID from 'ga' object, get campaign query parameters
    //
    // If the 'ga' object isn't available, or extraction of the client ID
    // otherwise fails, we should get an exception which is caught by the 'try'
    // block and no request is made.
    try {
      var url = FN.config.urls.licensingBase + 'trial_trackings';
      var tracker = ga.getAll()[0];
      var clientId = tracker.get('clientId');
      var data = { ga_client_id: clientId };
      var query = window.location.search;
      var validCampaignParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_id',
        'utm_mt',
        'utm_device',
        'lpurl',
        'gclid',
        'fbclid',
        'msclkid'
      ];
      var campaignParams, campaignParam;
      var i;

      // Extract campaign params from query string into [key, value] arrays
      campaignParams = query.slice(1).split('&').map((pair) => pair.split('='));

      for (i = 0; i < campaignParams.length; i++) {
        var campaignParam = campaignParams[i];
        if (validCampaignParams.indexOf(campaignParam[0]) > -1) {
          data[campaignParam[0]] = campaignParam[1];
        }
      }

      data = JSON.stringify(data);

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: data
      });
    }
    catch { }
  }

  const trialButtons = document.querySelectorAll(".button--start-trial");
  trialButtons.forEach(trialButton => {
    trialButton.addEventListener("click", () => {
      trackTrial();
    });
  });
});
