document.addEventListener('DOMContentLoaded', () => {
  const closeButtons = document.querySelectorAll(".modal-close");
  closeButtons.forEach(closeButton => {
    closeButton.addEventListener("click", () => {
      const modal = closeButton.closest(".modal-view");
      modal.classList.remove("modal-view-reveal");
      modal.classList.add("modal-view-hide");
      setTimeout(() => {
        modal.close();
        modal.classList.remove("modal-view-hide");
      }, 300);
    });
  });

  const modalAppIconImage = document.querySelector(".modal-app-icon img");
  if (modalAppIconImage) {
    if (modalAppIconImage.complete) {
      modalAppIconImage.parentElement.classList.add("modal-app-icon-loaded");
    } else {
      modalAppIconImage.onload = () => {
        modalAppIconImage.parentElement.classList.add("modal-app-icon-loaded");
      };
    }
  }

  const afterDownloadModal = document.getElementById("after-download-modal");
  // There are multiple buttons, one for each platform
  const downloadButtons = document.querySelectorAll(".nav-top-download a, .hero-download a");
  downloadButtons.forEach(downloadButton => {
    downloadButton.addEventListener("click", event => {
      event.preventDefault();
      const isMobile = window.matchMedia("(max-width: 900px)").matches;

      if (isMobile) {
        const nav = document.querySelector(".nav-top-common");
        nav.classList.remove("active");

        const trialViaEmailModal = document.getElementById("trial-via-email");
        trialViaEmailModal.showModal();

      } else {
        const body = document.getElementsByTagName("body")[0];
        const iframe = document.createElement("iframe");
        iframe.src = downloadButton.href;
        iframe.style.display = "none";
        body.appendChild(iframe);

        afterDownloadModal.showModal();
        afterDownloadModal.classList.add("modal-view-reveal");
      }
    });
  });

  const afterSubscribeModal = document.getElementById("after-subscribe-modal");
  document.addEventListener("TowerNewsletterSubscribed", event => {
    if (afterDownloadModal.open) {
      afterDownloadModal.close();
      afterSubscribeModal.showModal();
      afterSubscribeModal.classList.add("modal-view-reveal");
    }
  });
});
